/**
 * src/css/components/_tabs.scss
 * 
 * @author Johannes Braun <johannes.braun@hannenz.de>
 * @package donauratzen
 */
.tabs {
	ul {
		list-style: none;
	}

	&__nav {
		ul {
			display: flex;
			align-items: stretch;
		}
		ul, li {
			margin: 0;
		}

		.tab {
			flex: 1 1 auto;
			width: calc(100% / 6);
		}

		.tab__link  {
			display: block;
			text-decoration: none;
			color: currentColor;
			height: 100%;
			/* word-wrap: break-word; */

			&:active,
			&:focus {
				border: 0;
				outline: 0;
			}
		}
	}

	&__content {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	.tab-pane {
		display: none;

		/* &--is-active, */
		/* &:target { */
		/* 	display: block; */
		/* } */
	}
	.tab-pane--is-active,
	.tab-pane:target {
		display: block;
	}
}

/**
 * src/css/partials/_intern.scss
 *
 * @author Johannes Braun <johannes.braun@hannenz.de>
 * @package donauratzen
 */
.intern > .inner-bound {
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 3 * $base-line-height;
}

.main-column {
	order: 2;
	flex: 1;
	min-height: 70vh;

	> .headline:first-child {
		margin-top: 0;
	}
}

.intern .sidebar {
	width: 100%;
	order: 1;

	.subheadline {
		color: $polo-blue;
	}

	select {
		width: 100%;
		margin-bottom: 3rem;
	}
}


@include media($medium-and-up) {
	.intern .sidebar {
		background-color: $nero;
		flex: 0 1 200px;
		width: 200px;
		min-width: 200px;
		order: 3;
		margin-left: 2rem;
		padding: 0 1rem;
	}
}

.intern-nav {
	
	display: none;


	ul {
		list-style: none;
	}

	.intern-nav--is-current {
		position: relative;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: -1rem;
			border: 10px solid rgba(255, 255, 255, 0);
			border-left-color: rgba(255, 255, 255, 1);
			width: 0;
			height: 0;
		}

		a {
			color: $polo-blue;
		}
	}

	a {
		text-decoration: none;
		color: $steel-blue;

		&:hover {
			text-decoration: underline;
		}
	}
}
@include media ($medium-and-up) {
	.intern-nav {
		display: block;
	}
	.intern-nav--mobile {
		display: none;
	}
}

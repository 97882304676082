
.button {

	@include type-setting (0);

	display: inline-block;
	border-radius: 2px;
	border: 0;
	outline: 0;
	box-shadow: none;
	background-image: none;
	background-color: $cinnamon;
	text-decoration: none;
	text-align: center;
	color: #fff;
	padding: 0.25 * $base-line-height 0.5em;

	&::before {
		content: '> ';
		width: 0px;
		overflow: hidden;
		display: inline-block;
		transition: width 100ms ease-out;
		line-height: 1;
		vertical-align: middle;
	}

	&:hover::before {
		width: 15px;
	}
}

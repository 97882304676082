/**
 * src/css/components/_links.scss
 *
 * @author Johannes Braun <johannes.braun@hannenz.de>
 * @package donauratzen
 */

.links {
	margin: 2 * $base-line-height 0;
}

.link-list {
	list-style: none;
	display: flex;
	flex-wrap: wrap;

	&__item {
		width: calc(100% / 3);
		@include media ($medium-and-up) {
			width: calc(100% / 4);
		}
		@include media ($large-and-up) {
			width: calc(100% / 5);
		}
		@include media ($xl-bp) {
			width: calc(100% / 6);
		}
		padding: 10px;
	}
}

.link {
	text-decoration: none;
	display: block;
	display: flex;
	flex-direction: column;
	text-align: center;

	&__image {
		flex: 1;
		img {
			width: 100%;
			max-width: 100%;
		}
	}
	&__title {
		@include type-setting (0);
		color: $cinnamon;
	}
}


.main-nav {

	z-index:3;
	position: absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;

	ul {
	    position:absolute;
	    top:-99vh;
	    left:0;
	    width:100%;
	    height:101vh;
	    background-color:$nero;
	    z-index:2;
	    display:flex;
	    flex-direction:column;
	    align-items:center;
	    justify-content:center;
	    list-style-type:none;
	    padding-top:30vh;
	}

	li {
		flex:0 1 auto;
		// width:calc(100% / 7);

	    a {
		    color:#fff;
		    @include type-setting(1);
			// min font size for small viewports
			@include media ($medium-and-down) {
				font-size: 20px;
			}
		    margin:0.25rem 0;
		    text-align:center;
		    text-decoration:none;
		    text-transform:uppercase;
		    display:block;
		    text-align:center;
		    font-weight:900;
		    position:relative;
		    font-family:$head-font;
		    font-weight:400;

		    &::after {
		        content:'';
		        position:absolute;
		        bottom:0;
		        left:50%;
		        transform:translate(-50%);
		        height:4px;
		        width:0%;
		        background-color:$chelsea-gem;
		        transition:width 200ms ease-out;

		    }

		    &:hover::after {
		        width:100%;
		    }
	    }
    }
}

.main-nav__trigger {
    border:0;
    outline:0;
    margin:0;
    padding:0;
    background-color:transparent;
    position:fixed;
    top: 8px;
    right:12px;
    cursor:pointer;
    z-index:2;
	transition: 150ms ease-out;
    svg {
        width:32px;
        height:32px;
        display:block;
    }

	.trigger__menu {
		display: block;
	}
	.trigger__close {
		display: none;
	}
}

body.menu-is-open {
    .main-header {
        transform:translateY(70vh);
    }
	.main-nav__trigger {
		&:hover {
			transform: rotate(90deg);
		}
	}
	.trigger__close {
		display: block;
	}
	.trigger__menu {
		display: none;
	}
}

.logo {
    position: fixed;
	min-width: 90px;
    max-width:10rem;
    width:20vw;
    height:calc(100% + 2 * 30px);
	max-height: 130px;
    top:0;
    left:1rem;
	z-index:4;
    background-color:$chelsea-gem;
    background-image:linear-gradient(to right, $chelsea-gem 0%, $chelsea-gem 50%, $indian-tan 50%, $indian-tan 100%);
    clip-path:polygon(0 0, 0 100%, 50% 90%, 100% 100%, 100% 0);
    box-shadow:inset 0 8px 6px rgba(30, 30, 30, 0.2);

	@include media ($medium-and-up) {
		max-height: 240px;
	}

	img {
	    position:absolute;
	    bottom:20px;
	    max-width:100%;
	    display:block;
		@include media ($medium-and-up) {
			bottom: 32px;
		}
	}
}

.secondary-logo-container {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 3;
	@extend .inner-bound;
	display:flex;
	justify-content: flex-end;

	@media screen and (max-width: 1100px) {
		margin-right: 80px;
	}
}

.logo--secondary {
	z-index: 10;
	position: relative;
	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		box-shadow: inset 0 8px 6px rgba(30, 30, 30, 0.2);
	}


	img {
		transform: translateY(-4px);
		height: 5rem;
	}
}

@media screen and (min-width:800px) {
    .main-nav {
        li {
            a {
	            @include type-setting(2);
	        }
	    }
	}
	.logo {
	    height:calc(100% + 2 * 50px);
	}
}

.footer-nav {
    // position:absolute;
    // bottom:0;
    // left:0;
    // right:0;
    // height:150px;
    // z-index:3;

    ul {
        height:100%;
        list-style-type:none;
        max-width:$inner-width;
        margin:0 auto;
        display:flex;
        justify-content:flex-end;
        align-items:flex-end;
    }
    li {
        margin:0 0.25rem;
    }
    a {
        color:#fff;
        text-decoration:none;
        @include type-setting(0);
        &:hover {
            text-decoration:underline;
        }
    }
}

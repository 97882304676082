.stage {
	position: relative;
	margin-top: 75px;
	@include media ($medium-and-up) {
		margin-top: 150px;
	}

    &::after {
        content:'';
        position:absolute;
        bottom: 0;
        left:0;
        width:100%;
        height:50px;
        clip-path:polygon(0 0, 0 100%, 100% 100%, 100% 50px);
        background-color: $bg-color;
    }
}

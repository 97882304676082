.image-and-text {
	width: 100%;
	max-width: 100%;
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: flex-start;

	&__image {
		flex: 1 0 auto;
		max-width:200px;
		margin-right: $gutter;

		img {
			max-width: 100%;
			width: 100%;
			border: 6px solid #fff;
			box-shadow: 4px 6px 6px rgba(30, 30, 30, 0.2);
		}
	}

	&__text {
		flex: 1;
		/* max-width:400px; */
	}

}

.departments {
	list-style: none;
}

.department {
	/* margin-top: 2 * $base-line-height; */

	@for $i from 1 through 6 {
		&:nth-child(#{$i}) {
			$color: nth(nth($palette, $i + 2), 2);


			.department__name {
				color: $color;
			}
			.contact-block__image {
				border-color: $color;
			}
		}
	}
}

.contact-block {
    display:flex;
    align-items:center;
    flex-direction:column;
    margin-bottom:1 * $base-line-height;

    &__image {
        border-radius:50%;
        width:14rem;
        max-width:14rem;
        height:14rem;
        max-height:14rem;
        // margin-right:10px;
        border:5px solid $reno-sand;
        transition:150ms;
    }

    &:hover &__image {
        transform:scale(0.9);
    }

    &__caption {
        @include type-setting(1);
        font-style:normal;
        text-align:center;
    }

    &__name {
        /* color:$cinnamon; */
    }

    &__email {
        @include type-setting(0);
		a {
			/* color: $tangaroa; */
		}
    }
}

.contacts {
    display:flex;
    flex-wrap:wrap;
    justify-content:flex-start;
    .contact-block {
		width: 50%;
		@include media ($medium-and-up) {
			width: calc(100% / 3);
		}
    }
}

/*o
 * components/_news.scss
 *
 * @author Johannes Braun <johannes.braun@hannenz.de>
 * @package donauratzen
 */
.news-teasers {
	@include row ();
	
	display:flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top:$base-line-height;
	@include media ($medium-and-up) {
		flex-wrap: nowrap;
	}
}

.news-teaser {
	width: 100%;
	margin-bottom: $base-line-height;
	@include media ($medium-and-up) {
		width:31%;
	}

	display:flex;
	flex-direction:column;

	position:relative;
	&__image {
		height: 12 * $base-line-height;
		@include media ($medium-and-up) {
			height:8 * $base-line-height;
		}
		overflow:hidden;
		position:relative;

		img {
			display:block;
			width: 100%;
			max-width:100%;
			min-height: 100%;
			object-fit:cover;
			position:absolute;
			top:50%;
			left:50%;
			transform:translate(-50%, -50%);

		}
	}
	&__date {
		display: none;
		background-color:$cinnamon;
		color:#fff;
		position:absolute;
		top:9.5 * $base-line-height;;
		@include media ($medium-and-up) {
			top: 6.5 * $base-line-height;
		}
		padding:4px;
	}
	&__title {
		font-family:Merriweather, serif;
		font-weight:600;
		font-size:1.25em;
		// margin-top:$base-line-height;
		@include  type-setting(1);

		margin-top:0.75 * $base-line-height;
		margin-bottom:0.25 * $base-line-height;

	}
	&__action {
		margin-top:auto;

		.button {
			margin-top: $base-line-height;
		}
		/* a { */
		/* 	margin:1em 0; */
		/* 	display:inline-block; */
		/* 	background-color:$steel-blue; */
		/* 	color:#fff; */
		/* 	padding:0.25 * $base-line-height 0.5em; */
		/* 	border-radius:2px; */
		/* 	text-decoration:none; */
		/* 	&::before { */
		/* 	    content:'> '; */
		/* 	    width:0px; */
		/* 	    overflow:hidden; */
		/* 	    display:inline-block; */
		/* 	    transition:width 100ms ease-out; */
		/* 	    line-height:1; */
		/* 	    vertical-align:middle; */
		/* 	} */
		/* 	&:hover::before { */
		/* 	    width:15px; */
		/* 	} */
		/* } */
	}
}

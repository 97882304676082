/**
 * _carousel.scss
 */
.carousel {
    list-style: none;
    height: calc(100vh - 75px);
    overflow: hidden;
    /* background-color: $nero; */
	@include media ($medium-and-up) {
		height: calc(100vh - 200px);
	}
}


.carousel__slide { 
    position: relative; 
	width: 100%; 
	height: 100%;
	background-image: attr(data-img);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	background-position: center 66%;
}

// See a fallback/polyfill solution here: 
// https://medium.com/@primozcigler/neat-trick-for-css-object-fit-fallback-on-edge-and-other-browsers-afbc53bbb2c3
.carousel__image {
	display: none;
    object-fit: cover;
	object-position: center center;
	min-width: 100%;
	min-height: 100%;
}


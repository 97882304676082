.icon {
	display:inline-block;
	width: 1em;
	height: 1em;
	stroke: lime;
	stroke-width: 20px;

	path {
		stroke: lime;
	}
}

/**
 * src/css/partials/_departments.scss
 * 
 * @author Johannes Braun <johannes.braun@hannenz.de>
 * @package donauratzen
 */
$department-palette: (
	$nero,
	$cinnamon,
	$indian-tan,
	$polo-blue,
	$steel-blue,
	$tangaroa
);

$department-palette-fg: (
	#fff,
	#fff,
	$nero,
	$tangaroa,
	$tangaroa,
	#fff
);

$department-palette-fg2: (
	$indian-tan,
	$indian-tan,
	$cinnamon,
	$steel-blue,
	$polo-blue,
	$steel-blue
);

.departments {
	margin-top: 3 * $base-line-height;
		.tab {
			position: relative;
			&::after {
				content: '';
				display: none;
				position: absolute;
				top: -4px;
				left: 0;
				width: 100%;
				height: 4px;
				background-color: hotpink;
			}
		}

		.tab--is-active {
			font-weight: bold;
			&::after {
				display: block;
			}
		}

	.tab__link {
		padding: 4px 8px;

	}
	.tabs__content {
		margin-left: calc(50% - 50vw);
		margin-right: calc(50% - 50vw);
	}

	.department {
		@extend .inner-bound;
	}

	@for $i from 1 through 6 {
		.tab:nth-child(#{$i}) {
			background-color: nth($department-palette, $i);
			color: nth($department-palette-fg, $i);
			/* font-weight: ; */
		}
		.tab:nth-child(#{$i})::after {
			background-color: nth($department-palette, $i);
		}
		
		.tab-pane:nth-child(#{$i}) {
			background-color: nth($department-palette, $i);
			color: nth($department-palette-fg, $i);
			.department__name,
			.contact-block__name
			{
				color: nth($department-palette-fg, $i);
			}
			.contact-block__function,
			.contact-block__email a 
			{
				color: nth($department-palette-fg2, $i);
			}
			.contact-block__image {
				border-color: nth($department-palette-fg2, $i);
			}
		}
	}

	.tab-pane {
		padding: $base-line-height 0;
		min-height: 30 * $base-line-height;
	}
}

/**
 * src/css/components/_parade-eyecatcher.scss
 *
 * @author Johannes Braun <johannes.braun@hannenz.de>
 * @package donauratzen
 */
.parade-eyecatcher {
	text-align: center;
	@include type-setting(3);

	background-color: $tangaroa;
	background-image: linear-gradient(lighten($tangaroa, 7%) 50%, $tangaroa 50%, $tangaroa);
	background-image: linear-gradient($tangaroa 50%, lighten($tangaroa, 7%) 50%, lighten($tangaroa, 7%));
	width: 350px;
	height: 350px;
	position: relative;
	left: 50%;
	transform: translate(-50%, -120px) rotate(-10deg);
	border: 12px solid #fff;
	border-radius: 50%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	transition: 150ms;
	
	&:hover {
		transform: translate(-50%, -120px) rotate(-15deg) scale(1.1);
	}

	&__headline {
		@include type-setting(3);
		margin: 0;
		color: $steel-blue;
	}

	&__text {
		color: #fff;
		line-height: 1;
		margin-top: 18px;
		max-width: 10ch;
	}
}

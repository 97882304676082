@font-face {
	font-family: "Merriweather";
	font-style: normal;
	font-weight: 300;
	src: url("/dist/fonts/merriweather-v30-latin-300.woff2") format("woff2"), url("/dist/fonts/merriweather-v30-latin-300.woff") format("woff");
}
@font-face {
	font-family: "Merriweather";
	font-style: normal;
	font-weight: 400;
	src: url("/dist/fonts/merriweather-v30-latin-regular.woff2") format("woff2"), url("/dist/fonts/merriweather-v30-latin-regular.woff") format("woff");
}
@font-face {
	font-family: "Merriweather";
	font-style: normal;
	font-weight: 700;
	src: url("/dist/fonts/merriweather-v30-latin-700.woff2") format("woff2"), url("/dist/fonts/merriweather-v30-latin-700.woff") format("woff");
}
@font-face {
	font-family: "Rammetto One";
	font-style: normal;
	font-weight: 400;
	src: url("/dist/fonts/rammetto-one-v14-latin-regular.woff2") format("woff2"), url("/dist/fonts/rammetto-one-v14-latin-regular.woff") format("woff");
}

$head-font:'Rammetto One', serif;
$copy-font:'Merriweather', serif;

// https://css-tricks.com/font-size-viewport-units/#more-264858
:root {
	// Fluid typography for smaller viewports
	font-size: calc((1vw + 1vh + 0.5vmin) * 0.66);
	// Lock to max font-size for large viewports
	@include media ($large-and-up) {
		font-size: $base-font-size;
	}

	@include media ($small-bp) {
		font-size: 11px;
	}
}
	

body {
	/* font-size:$base-font-size; */
	font-size: 1rem;
	color:$text-color;
	line-height:1.2;
	// font-family:Lato,sans-serif;
	font-family:$copy-font;
}

h1,h2,h3,h4,h5,h6 {
	font-family:$head-font;
	color:$cinnamon;
	font-weight:400;
	@include type-setting(2, 0);
}

.title {
    @include type-setting(3);
	/* &:not(:first-of-type) { */
		margin-top:3 * $base-line-height;
	/* } */
    margin-bottom:1 * $base-line-height;
}

.headline {
    @include type-setting(2);
    margin-top:3 * $base-line-height;
    margin-bottom:1 * $base-line-height;
}

.subheadline {
    @include type-setting(1);
    margin-top:0.8 * $base-line-height;
    margin-bottom:0.2 * $base-line-height;
}

.intro-text {
    @include type-setting(2);
    max-width: 40em;
}

p {
    @include type-setting(1, 1);
}
.copy-text {
    @include type-setting(1, 1);
    max-width:50em;
}

figcaption {
    font-style:italic;
    @include type-setting(1, 1);
    color:lighten($text-color, 20%);
}

.blockquote {
    @include type-setting(2);
    margin:2 * $base-line-height auto;
    color:$tangaroa;
    font-style:italic;
    display:inline-block;
	font-family: $head-font;
	width: 100%;
	max-width: 14em;
	position: relative;
	text-align: center;
	padding-left: 80px;
	padding-right: 80px;
	display: block;

	&::before,
	&::after {
		position: absolute;
        font-size: 80px;
        color: $polo-blue;
        vertical-align: middle;
	}
    
    &::before {
		left: 0;
        content: open-quote;
    }
    &::after {
		right: 0;
        content: close-quote;
    }
}


.copy-text {
    b, strong {
        color:$nero;
    }
    a {
        color:$steel-blue!important;
        text-decoration:underline;
        &:hover {
            text-decoration:none;
        }
    }
}

.main-content {
	ul {
		list-style-position: inside;
		margin: $base-line-height 0;
	}
	
	li {
		margin-bottom: 0.5 * $base-line-height;
	}
}

img {
	max-width: 100%;
}

.main-header {
	transition:150ms ease-in-out;
	transition: 250ms ease-out;
	overflow:visible;
	position:fixed;
	width:100%;
	top:0;
	left:0;
	z-index:2;
	height:3 * 30px;

	.fringes {
	    transition:150ms ease-in-out;
    	position: absolute;
    	top: 0;
    	left: 0;
    	right: 0;
    	z-index:1;
    	height:100%;
	    background-color:$bg-color;
	    z-index:3;
	    box-shadow:0 0 8px rgba(30, 30, 30, 0.3);
	}
}

.has-scrolled .main-header .fringes {
    height:1.75 * 50px;
}


.fringe {
    background-repeat: repeat-x;
    background-position: 0 0;
    background-color: transparent;
    background-size:30px 60px;

    position:absolute;
    left:0;
    right:0;
    height:100px;

    &--nero {
        $chevron:build-chevron($nero);
        /* background-image: url('data:image/svg+xml,#{$chevron}'); */
        background-image: url('/dist/img/chevron_nero.svg');
    }
    &--chelsea-gem {
        $chevron:build-chevron($chelsea-gem);
        /* background-image: url('data:image/svg+xml,#{$chevron}'); */
        background-image: url('/dist/img/chevron_chelsea_gem.svg');
    }
    &--cinnamon {
        $chevron:build-chevron($cinnamon);
        /* background-image: url('data:image/svg+xml,#{$chevron}'); */
        background-image: url('/dist/img/chevron_cinnamon.svg');
    }

    &--tangaroa {
        $chevron:build-chevron($tangaroa);
        /* background-image: url('data:image/svg+xml,#{$chevron}'); */
        background-image: url('/dist/img/chevron_tangaroa.svg');
    }
    &--steel-blue {
        $chevron:build-chevron($steel-blue);
        /* background-image: url('data:image/svg+xml,#{$chevron}'); */
        background-image: url('/dist/img/chevron_steel_blue.svg');
    }
    &--polo-blue {
        $chevron:build-chevron($polo-blue);
        /* background-image: url('data:image/svg+xml,#{$chevron}'); */
        background-image: url('/dist/img/chevron_polo_blue.svg');
    }
}

.main-header {
	.fringe {
    	&:nth-child(1) {
        	top:0;
        	z-index: 3;
    	}
    	&:nth-child(2) {
        	top:30px;
        	z-index: 2;
    	}
    	&:nth-child(3) {
            top:60px;
        	z-index: 1;
    	}
    }
}

@media screen and (min-width:800px) {
    .main-header {
        height:3 * 50px;
    }

    .fringe {
        background-size:50px 100%;
    }
    .main-header {
        .fringe {
            &:nth-child(2) {
                top:50px;
            }
            &:nth-child(3) {
                top:100px;
            }
        }
    }

    .has-scrolled .main-header .fringes {
        height:3.25 * 50px;
    }
}


$parade-bg-color: $cinnamon;
$parade-fg-color: $chelsea-gem;
$ball-bg-color: $chelsea-gem;
$ball-fg-color: $cinnamon;
$meeting-bg-color: $polo-blue;
$meeting-fg-color: $tangaroa;
$misc-bg-color: $tangaroa;
$misc-fg-color: $steel-blue;

body:not(.is-logged-in) {
	.event--is-internal {
		display: none;
	}
}

body.is-logged-in {
	.show-only-internal {
		.event--is-internal:not(.is-hidden) {
			display: flex;
		}
		.event--is-public {
			display: none;
		}
	}
	.show-only-public {
		.event--is-internal {
			display: none;
		}
		.event--is-public:not(.is-hidden) {
			display: flex;
		}
	}
}

.event {
    display:flex;
    justify-content:flex-start;
    margin-bottom:3 * $base-line-height;
    margin-left:calc(50% - 50vw);
    margin-right:calc(50% - 50vw);
    padding-left:calc(-1 * (50% - 50vw));
    padding-right:calc(-1 * (50% - 50vw));

	&.is-hidden {
		display: none;
	}

	&:target {
		padding-top: 200px;
		/* outline: 1px dashed hotpink; */
	}

	.subheadline {
		color: currentColor;
		@include type-setting(0);
	}

    &__title {
        @include type-setting(2);
		line-height: 1.2;
        font-weight:bold;
		margin-bottom: $base-line-height * 0;
    }

    &__date {
        flex:0 0 auto;
        margin-right:1rem;
    }

    &__location {
		margin-bottom: $base-line-height;
    }

	&__description {
		margin-bottom: $base-line-height;
	}


	color: $cinnamon;

	&--parade {
		color: $parade-bg-color;
		.date {
			background-color: $parade-bg-color;
			&__weekday, &__year {
				color: $parade-fg-color;
			}
		}
	}

	&--ball {
		color: $ball-bg-color;
		.date {
			background-color: $ball-bg-color;
			&__weekday, &__year {
				color: $ball-fg-color;
			}
		}
	}

	&--meeting {
		color: $meeting-bg-color;
		.date {
			background-color: $meeting-bg-color;
			&__weekday, &__year {
				color: $meeting-fg-color;
			}
		}
	}

	&--misc {
		color: $misc-bg-color;
		.date {
			background-color: $misc-bg-color;
			&__weekday, &__year {
				color: $misc-fg-color;
			}
		}
	}

	&__attachments,
	&__departures,
	&__returns {
		padding-left: 1em;
	}

	&__internal-notes {
		margin-top: $base-line-height;
	}
}

.date {
    max-width:110px * 0.8;
    max-height:200px * 0.8;

	width: 15vw;
	height: calc(15vw * 200 / 110);
    color:#fff;
    clip-path:polygon(0 0, 0 100%, 50% 90%, 100% 100%, 100% 0);
    display:flex;
    flex-direction:column;
    align-items:center;
    padding:1rem 0 2rem 0;

    > * {
        line-height:1;
    }
    &__weekday, &__year {
        color:$chelsea-gem;
    }
        
    &__day {
        font-size: 200%;
        font-weight:bold;
		margin-bottom: 0.5rem;
    }
    &__month {
        text-transform:uppercase;
        font-size:125%;
		margin-bottom: 0.25em;
    }
}

.calendar {
	table {
		width: 100%;
		border-collapse: collapse;
		td, th {
			vertical-align: middle;
			text-align: center;
			padding: 0.25em;
			color: $nero;
		}

		th {
			color: $chelsea-gem;
		}

		a {
			display: block;
			background-color: $cinnamon;
			color: #fff;
			text-decoration: none;
			padding: 0.25em;
			
			&.event--parade {
				background-color: $parade-bg-color;
			}

			&.event--ball {
				background-color: $ball-bg-color;
			}

			&.event--meeting {
				background-color: $meeting-bg-color;
			}

			&.event--misc {
				background-color: $misc-bg-color;
			}

			&.is-hidden {
				background-color: transparent;
				color: $nero;
			}
		}
	}
}

.month-select {
	display: flex;
	align-items: center;
	border-bottom: 1px solid $cinnamon;

	&__button {
		width: 20px;
		height: 20px;
		max-height: 20px;
		justify-contentt: 20px;
		padding: 0;
		color: lime !important; //$cinnamon !important;
		text-align: center;
		text-decoration: none;
	}

	&__current {
		@include type-setting (1);
		flex: 1;
		text-align: center;
	}
}

.event-filters {
	hr {
		display:none;
		margin: 0.5 * $base-line-height 0;
	}
}

.event-filter {
	input[type=checkbox] {
		display: none;
	}

	label {
		padding-left: 1.5rem;
		position: relative;

		&::before {
			content: '';
			width: 1.25rem;
			height: 1.25rem;
			position: absolute;
			left: 0;
			top: 0.125em;
		}

		&[for=filter-parade]::before {
			background-color: $parade-bg-color;
		}

		&[for=filter-ball]::before {
			background-color: $ball-bg-color;
		}

		&[for=filter-meeting]::before {
			background-color: $meeting-bg-color;
		}

		&[for=filter-misc]::before {
			background-color: $misc-bg-color;
		}
	}

	input[type=checkbox]:checked + label::after {
		content: '\2715';
		position: absolute;
		left: 0.125em;
		top: 0.125em;
		color: #fff;
	}
}

.inner-bound {
    max-width:$inner-width;
    margin:0 auto;

	padding: 0 1rem;
	@media screen and (min-width: $inner-width) {
		padding: 0;
	}
}


body {
	/* display: flex; */
	/* flex-direction: column; */
    /*  */
	// Working around a flexbug (IE11)
	height: 100vh;
	/* min-height: 100vh; */

	max-width: 100vw;
	overflow-x: hidden;

	// &.is-logged-in {
	// 	border:4px solid  $steel-blue;
	// }
}



$chevron-color:tint($cinnamon, 90%);
.main-content {
	flex: 1;
    padding-top:calc(3 * #{$base-line-height} + 100px);
    min-height:100px;
    background-color:$bg-color;
    position:relative;
    z-index:1;
    $chevron:build-chevron2($chevron-color);
    background-image: url('data:image/svg+xml,#{$chevron}');
	background-repeat:repeat-x;
	background-position: -15% 50vh;
	background-size:66%;
	padding-bottom:6 * $base-line-height;
}

.container {
	display: flex;
	flex-wrap: wrap;
	.main {
		flex: 1;
	}
	.sidebar {
		width: 300px;
		margin-bottom: $base-line-height;
		order: -1;
		@include media ($medium-and-up) {
			order: 1;
			margin-left: $gutter;
		}

		.stick {
			position: sticky;
			top: 200px;
		}
	}
}

.main-content figure img {
	/* width: 100%; */
	/* max-width: 100%; */
}



// No top padding on home page
#page-2 {
	.main-content {
		padding-top: 0;
	}
}


// Equal image sizing on history page
#page-4 {
	.main-content {
		img {
			max-width: 66.66%;
			width: 66.66%;
			height: auto;
		}
	}
}

/**
 * _settings.scss
 *
 * @author Johannes Braun <johannes.braun@hannenz.de>
 * @package donauratzen
 */


$inner-width: 1000px;


/* ------------
 * Colors
 * -----------*/

$palette: (
    nero:			#341f00,	// darkest brown, low saturation
    cinnamon:		#523002,	// a cinnamon brown (medium brown)
    indian-tan:		#804a02,	// a bit like "kastanie" (darker) brown
    chelsea-gem:	#b96a03,	// "ocker"
    reno-sand:		#ac772b,	// häs main color
    polo-blue:		#8fb3e3,	// the lighter of the light blues
    steel-blue:		#558ed5,	// the darker of the light blues
    tangaroa:		#112442		// a kind of navy blue
);

$nero:			map-get($palette, nero);
$cinnamon:		map-get($palette, cinnamon);
$indian-tan:	map-get($palette, indian-tan);
$chelsea-gem:	map-get($palette, chelsea-gem);
$reno-sand:		map-get($palette, reno-sand);
$polo-blue:		map-get($palette, polo-blue);
$steel-blue:	map-get($palette, steel-blue);
$tangaroa:		map-get($palette, tangaroa);

$text-color: tint(#000, 20%);
$bg-color: #fffcf7;
$error-color: #800000;
$success-color: #008000;



/* ------------
 * Breakpoints
 * -----------*/

$breakpoints: (
    small: 530px,
    medium: 1024px,
    large: 1440px
);

$small-bp: new-breakpoint(max-width map-get($breakpoints, small));

$medium-bp: new-breakpoint(min-width map-get($breakpoints, small) max-width map-get($breakpoints, medium));
$medium-and-up: new-breakpoint(min-width map-get($breakpoints, small));
$medium-and-down: new-breakpoint(max-width map-get($breakpoints, medium));
$large-bp: new-breakpoint(min-width map-get($breakpoints, medium) max-width map-get($breakpoints, large));
$large-and-up: new-breakpoint(min-width map-get($breakpoints, medium));
$large-and-down: new-breakpoint(max-width map-get($breakpoints, large));
$xl-bp: new-breakpoint(min-width map-get($breakpoints, large));


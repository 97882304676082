form {
	max-width: 30em;

	.input {
		margin-bottom: 2 * $base-line-height;

		label {
			display: block;
		}

		input, textarea {
			font-size: 1rem;
			width: 100%;
			background-color: transparent;
			border: 0;
			border-bottom: 2px solid $nero;
			padding: 0.5 * $base-line-height 0;
		}

		&.error {
			/* label { */
			/* 	color: $error-color; */
			/* } */
			input, textarea {
				border-color: $error-color;
			}
		}
	}

	.input--text {
	}

	.input--textarea {
		textarea {
			min-height: 5 * $base-line-height;
		}
	}

	.input--submit {
		input,
		button {
			@extend .button;
		}
	}
}

.message {
	margin: 0.5 * $base-line-height 0;
	padding: 0.25 * $base-line-height;
	border-radius: 2px;

	&--error {
		background-color: $error-color;
		color: #fff;
	}

	&--success {
		background-color: $success-color;
		color: #fff;
	}
}

.validation-error-message {
	color: $error-color;
}

.main-footer {
	position:relative;
	padding-top:5 * $base-line-height;
	padding-bottom:1 * $base-line-height;
	background-color:$tangaroa;
	color:#fff;

	.inner-bound {
        display:flex;
		flex-wrap: wrap;
    }


	.fringes {
		position:absolute;
	    top: -2px; 			// Avoid a "blizter" in firefox
		left: 0;
		right: 0;
	    height: 5 * $base-line-height;
		z-index: 1;
		@media screen and (max-width: 800px) {
			top: -15px;
		}
	}
	.fringe {
		top: auto;
		bottom:0;
    	&:nth-child(1) {
        	transform:scaleY(-1) translateY(0px);
        	z-index: 3;
    	}
    	&:nth-child(2) {
        	transform:scaleY(-1) translateY(50px);
        	z-index: 2;
    	}
    	&:nth-child(3) {
        	transform:scaleY(-1) translateY(100px);
        	z-index: 1;
    	}

		@media screen and (max-width: 800px) {
			&:nth-child(1) {
				transform:scaleY(-1) translateY(0);
			}
			
			&:nth-child(2) {
				transform:scaleY(-1) translateY(30px);
			}
			
			&:nth-child(3) {
				transform:scaleY(-1) translateY(60px);
			}
		}
    }

    .address {
        @include type-setting(0);
        font-style:normal;
        a {
            color:#fff;
        }

		*:last-child {
			margin-bottom: 0;
		}
    }

    .footer-nav {
		margin-top: $base-line-height;
		@include media ($medium-and-up) {
			margin-left:auto;
		}
    }
}


/**
 * main.scss
 *
 * @author Johannes Braun <johannes.braun@hannenz.de>
 * @package donauratzen
 */

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@import "bourbon";
@import "neat";

@import "settings";
@import "mixins/mixins";
@import "functions/vertical-rhythm";
@import "partials/typography";
@import "partials/layout";
@import "partials/icons";
@import "partials/departments";
@import "partials/intern";
@import "components/stage";
@import "components/buttons";
@import "components/shapes";
@import "components/header";
@import "components/nav";
@import "components/news";
@import "components/carousel";
@import "components/footer";
@import "components/contact-block";
@import "components/events";
@import "components/image_and_text";
@import "components/form";
@import "components/links";
@import "components/tabs";
@import "components/cookie_notification";
@import "components/parade-eyecatcher";


// $visual-base-line-grid:true;
$visual-base-line-grid-offset:0px;
$visual-base-line-grid-above:false;

$copy-text-max-width:50em;

// Modular scale ("Augmented Fourth")
// http://type-scale.com/?size=18&scale=1.414&text=A%20Visual%20Type%20Scale&webfont=Merriweather&font-family=Merriweather%2C%20serif&font-weight=400&font-family-headers=&font-weight-headers=inherit&background-color=white&font-color=#333
$type-scale: (
    -2: 0.5rem,
	-1: 0.707rem,	
	 0: 1rem,	
	 1: 1.1414rem,	
	 2: 1.9999rem,	
	 3: 2.827rem,
	 4: 3.998rem
);

@function type-scale($level) {
	@return map-get($type-scale, $level);
}


// Vertical Rhythm
$base-font-size: 18 / 16 * 1rem;
$base-line-height: $base-font-size * 1.5; // "vertical unit"

$line-heights: (
    -2: $base-line-height * 1,
	-1: $base-line-height * 1,
	 0: $base-line-height * 1,
	 1: $base-line-height * 1,
	 2: $base-line-height * 2,
	 3: $base-line-height * 2,
	 4: $base-line-height * 4
);

@function line-height($level) {
	@return map-get($line-heights, $level);
}

// Visual baseline grid for debugging ("Grundlinienraster")
.debug-base-line-grid {
	background-image:linear-gradient(
		to bottom,
		transparent 0,
		transparent calc(#{$base-line-height}),
		rgba(tomato, 0.2) calc(#{$base-line-height}),
		rgba(tomato, 0.2)
	);
	background-size:100% (2 * $base-line-height);
	// @include position(absolute, 0 0 0 0);
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:1000%;
	bottom:auto;
	@if $visual-base-line-grid-above {
		z-index:1;
	}
	@else {
		z-index:0;
	}
	top:$visual-base-line-grid-offset;
	// opacity:0;
	display:none;
}

.debug-base-line-grid--is-visible {
	position:relative;
}
.debug-base-line-grid--is-visible .debug-base-line-grid {
	display:block;
}

@mixin type-setting($level:0, $margin-bottom:0) {
	$font-size:type-scale($level);
	$base-line-height:line-height($level);

	$line-height:($base-line-height / $font-size);
	
	font-size:$font-size;
	line-height:$line-height + em;
	margin-bottom:($line-height * $margin-bottom) + em;
	/* display:block; */
}


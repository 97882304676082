.cookie-notification {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: $nero;
	color: $indian-tan;
	@include type-setting(0);
	padding: 4px 1rem;
	display: flex;
	align-items: center;
	transition:  350ms;
	z-index: 100;

	&.fade-out {
		opacity: 0;
		transform:translateY(100%);
	}

	a {
		color: currentColor;
	}

	&__message {
		flex: 1;
		margin: 0;
		text-align: right;
		padding-right: 2rem;
		@include type-setting(0);
	}

	&__action-area {
		a {
			margin: 0 .5em;
		}
	}
	.cookie-notification__accept { 
		padding: 0 1em;
	}
}
